import React, { useState } from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Select from 'react-select';
import { Breadcrumbs } from '@mui/material';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { LineGraph } from '../components/charts/LineGraph';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import useFetchData from '../hooks/useFetchData';
import useFetchAnalytics from '../hooks/useFetchAnalytics';

function CohortLogins() {
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');
  const [interval, setInterval] = useState('');

  // get cohorts
  const { data: cohorts } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: cohort.name,
      }))
    : [];

  const intervalOptions = [
    {
      value: 'daily',
      label: 'Daily',
    },
    {
      value: 'weekly',
      label: 'Weekly',
    },
    {
      value: 'monthly',
      label: 'Monthly',
    },
  ];

  const { data: cohortLogins, isLoading } = useFetchAnalytics(
    ['cohort-logins', cohortId, interval],
    `/${interval}-logins/${cohortId}`,
    {},
    "Couldn't get cohort logins. Please try again",
    cohortId && interval
  );

  return (
    <>
      <MainContent>
        <div className=" space-y-5">
          <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/analytics"
                className="flex items-center"
              >
                <AnalyticsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Analytics</p>
              </NavLink>
            </Breadcrumbs>
          </div>
          <div className="bg-white px-2 py-5 rounded-lg">
            <p>
              <span className="font-medium">Cohort Logins Analytics: </span>
              <span className="underline underline-offset-1">
                Login statistics for learners, broken down by cohort
              </span>
            </p>
          </div>
          <div className="mt-4 grid grid-cols-4 bg-white p-3 gap-4 rounded-lg">
            <Select
              isClearable
              placeholder="Select Cohort"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
              onChange={(event) => {
                setCohortId(event?.value || null);
                setCohortName(event?.label || null);
              }}
            />
            <Select
              isClearable
              placeholder="Select Interval"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={intervalOptions}
              onChange={(event) => {
                setInterval(event?.value || null);
              }}
            />
          </div>
          {isLoading && cohortId && interval ? (
            <div className="flex items-center justify-center mt-5 bg-white py-4">
              <Spinner />
            </div>
          ) : (
            <div className="mt-4 grid grid-cols-1 gap-2 bg-white">
              <LineGraph
                title={
                  cohortLogins?.data?.title ||
                  `Student Logins ${cohortName ? `in ${cohortName}` : ''}`
                }
                colour="#8b173b"
                datasets={!cohortId ? [] : cohortLogins?.data?.datasets || []}
                labels={cohortLogins?.data?.labels}
                label="Number of learners"
              />
            </div>
          )}
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default CohortLogins;
