/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Select from 'react-select';
import Calendar from '../components/calendar/Calendar';
import { MultiVerticalBar } from '../components/charts/MultiVerticalBar';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import Spinner from '../components/spinner/Spinner';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import useFetchData from '../hooks/useFetchData';
import { LineGraph } from '../components/charts/LineGraph';

function ScholarshipStudentProgressAnalytics() {
  const [cohortCourseId, setCohortCourseId] = useState('');
  const [cohortModules, setCohortModules] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [cohortId, setCohortId] = useState('');
  const [cohortName, setCohortName] = useState('');

  // get courses
  const { data: courses } = useFetchData(
    ['courses', cohortCourseId],
    '/courses',
    { params: { courses_ids: JSON.stringify([cohortCourseId]) } },
    "Couldn't fetch courses. Please try again!",
    !!cohortId
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules
        .filter((courseModule) => cohortModules.includes(courseModule.id))
        .map((courseModule) => ({
          value: courseModule.id,
          label: courseModule.moduleName,
        }))
    : [];

  const { data: cohortData } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohortData
    ? cohortData?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: `${cohort.name} (${cohort.no_of_students || 0} Students)`,
        enrolled_course: cohort?.course_id ? cohort.course_id : null,
        enrolled_modules: cohort?.modules ? cohort?.modules : [],
      }))
    : [];

  const { data, isLoading } = useFetchAnalytics(
    ['sponsored-cohort-students-progress', cohortId, moduleId],
    `/students-progress/sponsored-cohort`,
    { params: { sponsored_cohort_id: cohortId, module_id: moduleId } },
    "Couldn't get students progress results. Please try again",
    cohortId && moduleId
  );

  const bgColours = ['#8b173b', '#00999e', '#ffe9f0'];

  return (
    <>
      <MainContent>
        <div className=" space-y-5">
          <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/analytics"
                className="flex items-center"
              >
                <AnalyticsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Analytics</p>
              </NavLink>
            </Breadcrumbs>
          </div>
          <div className="bg-white px-2 py-5 rounded-lg">
            <p>
              <span className="font-medium">
                Scholarship Modules Student Progress Analytics:{' '}
              </span>
              <span className="underline underline-offset-1">
                Comprehensive breakdown of students completion rates for each
                week within each module, segmented by scholarship cohort.
              </span>
            </p>
          </div>
          <div className="grid grid-cols-4 bg-white p-3 gap-4">
            <Select
              //   {...field}
              isClearable
              placeholder="Select Scholarship Cohort"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
              onChange={(event) => {
                setCohortCourseId(event?.enrolled_course);
                setCohortModules(event?.enrolled_modules);
                setCohortId(event?.value || null);
                setCohortName(event?.label || '');
              }}
            />
            <Select
              //   {...field}
              isClearable
              placeholder="Select Course"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={courseOptions}
              onChange={(event) => {
                setCourseId(event?.value || null);
              }}
            />
            <Select
              //   {...field}
              isClearable
              placeholder="Select Module"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={moduleOptions}
              onChange={(event) => {
                setModuleId(event?.value || null);
                setModuleName(event?.label || null);
              }}
            />
            {/* <Select
              //   {...field}
              isClearable
              placeholder="Select Week"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={weekOptions}
              onChange={(event) => {
                setWeekId(event?.value || null);
              }}
            /> */}
          </div>
          {/* {console.log('studentProgressLoading', studentProgressLoading)} */}
          {moduleId && cohortId && isLoading ? (
            <div className="mt-5 flex justify-center bg-white">
              <Spinner />
            </div>
          ) : (
            <div className="mt-5 grid grid-cols-2 gap-2 bg-white">
              <div className=" col-span-2">
                <LineGraph
                  title={`${moduleName ?? ''} Module Progress ${
                    cohortName ? ` - ${cohortName}` : ''
                  }`}
                  colour="#8b173b"
                  datasets={data ? data?.data?.graph_data.datasets : []}
                  labels={data ? data?.data?.graph_data.labels : []}
                  label="Number of students"
                />
              </div>
            </div>
          )}
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default ScholarshipStudentProgressAnalytics;
