import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HomeIcon from '@mui/icons-material/Home';
import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'react-hot-toast';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate, useParams } from 'react-router';
import Modal from '../components/Modal';
import TableWrapper from '../utils/TableWrapper';
import Spinner from '../components/spinner/Spinner';
import MainContent from '../components/MainContent';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import CreateSurvey from '../components/CreateSurvey';
import UpdateSurvey from '../components/updateSurvey';

function SurveyList() {
  const { weekId, weekName } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [surveyId, setSurveyId] = useState('');
  const [surveyName, setSurveyName] = useState('');

  const queryClient = useQueryClient();

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const toggleUpdateModal = () => {
    setUpdateModalOpen(!updateModalOpen);
  };

  const columns = [
    { title: 'Survey Name', field: 'name' },
    {
      title: 'Actions',
      render: (row) => (
        <div className="space-x-4">
          <button
            onClick={() =>
              navigate(
                `/courses/modules/weeks/survey-${row.name}/${row.id}/questions`
              )
            }
            type="button"
            className="p-1 px-2 bg-[#00999E] text-white rounded-md"
          >
            Add Questions
          </button>
          <button
            onClick={() => {
              setSurveyId(row.id);
              setSurveyName(row.name);
              toggleUpdateModal();
            }}
            type="button"
            className="p-1 px-2 bg-blue-700 text-white rounded-md"
          >
            Edit Survey
          </button>
          <button
            onClick={() => {
              setSurveyId(row.id);
              setSurveyName(row.name);
              toggleDeleteModal();
            }}
            type="button"
            className="p-1 px-2 bg-red-700 text-white rounded-md"
          >
            Delete Survey
          </button>
        </div>
      ),
    },
  ];

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const fetchSurveys = async () => {
    const response = await axiosInstancev2.get('/surveys', {
      params: { week_id: weekId },
    });
    return response;
  };

  const onFetchSurveysFailure = () => {
    toast.error("Couldn't get surveys. Please try again!");
  };
  const { data, isLoading } = useQuery(['week-surveys', weekId], fetchSurveys, {
    staleTime: 3600 * 1000,
    cacheTime: 3600 * 1000,
    onError: onFetchSurveysFailure,
  });

  const onSurveyDeletionSuccess = () => {
    toast.success('Survey successfuly deleted');
    toggleDeleteModal();
    queryClient.invalidateQueries(['week-surveys', weekId]);
  };

  const onSurveyDeletionError = () => {
    toast.error("Couldn't delete survey. Please try again");
  };

  const { mutate, isLoading: deletingSurvey } = useMutation(
    () => {
      return axiosInstancev2.delete(`/surveys/${surveyId}`);
    },
    {
      onSuccess: onSurveyDeletionSuccess,
      onError: onSurveyDeletionError,
    }
  );

  const deleteSurveyHandler = () => {
    mutate(surveyId);
  };

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/courses"
              className="flex items-center"
            >
              <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Courses</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-2)}
              to={() => navigate(-2)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Modules</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-1)}
              to={() => navigate(-1)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Module Overview</p>
            </NavLink>
          </Breadcrumbs>
          <button
            type="button"
            className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
            onClick={toggleModal}
          >
            Create Survey
          </button>
        </div>
        <div className="mt-4">
          {isLoading ? (
            <div className="w-full flex align-middle justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title={`Surveys in ${weekName}`}
              columns={columns}
              // eslint-disable-next-line no-unsafe-optional-chaining
              data={data ? (data?.data).surveys : []}
              // onRowClick={(event, rowData) => navigate(`/group/${rowData.id}`)}
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create Survey"
      >
        <CreateSurvey toggleModal={toggleModal} />
      </Modal>
      <Modal title="Delete Survey" modalOpen={deleteModalOpen}>
        <p>
          Are you sure you want to delete the survey on{' '}
          <span className="font-medium">{surveyName}</span>?
        </p>

        {deletingSurvey ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-10">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleDeleteModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="button"
              onClick={deleteSurveyHandler}
            >
              <DeleteIcon fontSize="inherit" />
              <p>Delete Survey</p>
            </button>
          </div>
        )}
      </Modal>
      <Modal title="Update Survey" modalOpen={updateModalOpen}>
        <UpdateSurvey
          toggleModal={toggleUpdateModal}
          surveyId={surveyId}
          surveyName={surveyName}
        />
      </Modal>
    </>
  );
}

export default SurveyList;
