import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

function DeleteTimetableEvent({
  toggleDeleteTimetableEventModal,
  timetableEventId,
}) {
  const queryClient = useQueryClient();
  const onTimetableEventDeletionSuccess = () => {
    toast.success('Timetable event successfully deleted!');
    toggleDeleteTimetableEventModal();
  };
  const onTimetableEventDeletionFailure = () => {
    toast.error('Timetable event deletion failed! Please try again');
  };

  const { mutate, isLoading } = useMutation(
    () => {
      return axiosInstancev2.delete(`/timetable-events/${timetableEventId}`);
    },
    {
      onSuccess: () => {
        onTimetableEventDeletionSuccess();
        queryClient.invalidateQueries(['timetable-events']);
      },
      onError: onTimetableEventDeletionFailure,
    }
  );

  return (
    <div>
      Are you sure you want to delete this timetable event?
      {isLoading ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex justify-between mt-8">
          <button
            className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
            type="button"
            onClick={toggleDeleteTimetableEventModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
            type="button"
            onClick={() => mutate()}
          >
            <SaveIcon fontSize="inherit" />
            <p>Delete Timetable Event</p>
          </button>
        </div>
      )}
    </div>
  );
}

export default DeleteTimetableEvent;
