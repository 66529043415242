import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Modal from '../components/Modal';
import Spinner from '../components/spinner/Spinner';
import usePermissions from '../hooks/usePermissions';
import CreateGroup from '../components/CreateGroup';
import TableWrapper from '../utils/TableWrapper';
import CreateAssignment from '../components/CreateAssignment';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import useFetchData from '../hooks/useFetchData';

const columns = [
  { title: 'Group Name', field: 'name' },
  { title: 'Max Group Size', field: 'size' },
  { title: 'Group Mode', field: 'join_mode' },
  {
    title: 'Total Members',
    field: 'total_members',
    render: (row) => <p>{row?.total_members || 0}</p>,
  },
  {
    title: 'Created On',
    field: 'created_at',
    render: (row) => <p>{new Date(row.created_at).toLocaleDateString()}</p>,
  },
];

function Groups() {
  const [modalOpen, setModalOpen] = useState(false);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);

  const navigate = useNavigate();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleAssignmentModal = () => {
    setAssignmentModalOpen(!assignmentModalOpen);
  };

  const { data, isLoading } = useFetchData(
    ['groups'],
    '/groups',
    {},
    "Couldn't get groups. Please try again!",
    true
  );

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          <div className=" flex space-x-2">
            {(permissions?.includes('CreateGroupAssignment') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleAssignmentModal}
              >
                Create Assignment
              </button>
            )}

            {(permissions?.includes('CreateGroup') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleModal}
              >
                Create Group
              </button>
            )}
          </div>
        </div>
        <div className="mt-4">
          {isLoading ? (
            <div className="mt-5 bg-white py-5">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title="List of Groups"
              columns={columns}
              // eslint-disable-next-line no-unsafe-optional-chaining
              data={data ? (data?.data).groups : []}
              onRowClick={(event, rowData) =>
                navigate(`/groups/${rowData.name}/${rowData.id}`)
              }
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create Group"
      >
        <CreateGroup toggleModal={toggleModal} />
      </Modal>
      <Modal
        modalOpen={assignmentModalOpen}
        toggleModal={toggleAssignmentModal}
        title="Create Assignment"
      >
        <CreateAssignment toggleModal={toggleAssignmentModal} />
      </Modal>
    </>
  );
}

export default Groups;
