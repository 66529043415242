import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Modal from '../components/Modal';
import Spinner from '../components/spinner/Spinner';
import usePermissions from '../hooks/usePermissions';
import TableWrapper from '../utils/TableWrapper';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import CreateEvent from '../components/CreateEvent';
import EditEvent from '../components/EditEvent';
import DeleteEvent from '../components/DeleteEvent';
import useFetchDatav2 from '../hooks/useFetchDatav2';

function Events() {
  const [createEventModalOpen, setCreateEventModalOpen] = useState(false);
  const [editEventModalOpen, setEditEventModalOpen] = useState(false);
  const [deleteEventModalOpen, setDeleteEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleCreateEventModal = () => {
    setCreateEventModalOpen(!createEventModalOpen);
  };

  const toggleEditEventModal = () => {
    setEditEventModalOpen(!editEventModalOpen);
  };

  const toggleDeleteEventModal = () => {
    setDeleteEventModalOpen(!deleteEventModalOpen);
  };

  const { data, isLoading } = useFetchDatav2(
    ['events'],
    '/events',
    {},
    "Couldn't get events. Please try again!",
    true
  );

  const columns = [
    { title: 'Event Name', field: 'eventName' },
    { title: 'Venue', field: 'eventVenue' },
    {
      title: 'Start Date',
      render: (row) => (
        <p>{`${new Date(row.eventStartDate).toLocaleDateString()} - ${new Date(
          row.eventStartDate
        ).toLocaleTimeString()}`}</p>
      ),
    },
    {
      title: 'End Date',
      render: (row) => (
        <p>
          {row.eventEndDate
            ? `${new Date(row.eventEndDate).toLocaleDateString()} - ${new Date(
                row.eventEndDate
              ).toLocaleTimeString()}`
            : ''}
        </p>
      ),
    },
    {
      title: 'Actions',
      render: (row) => (
        <div className="flex space-x-2">
          {(permissions?.includes('UpdateEvent') || role === 'SuperAdmin') && (
            <button
              className="bg-claret-500 text-white px-6 py-1 rounded-md"
              type="button"
              onClick={() => {
                toggleEditEventModal();
                setSelectedEvent(row);
              }}
            >
              Edit
            </button>
          )}
          {(permissions?.includes('DeleteEvent') || role === 'SuperAdmin') && (
            <button
              className="bg-red-500 text-white px-6 py-1 rounded-md"
              type="button"
              onClick={() => {
                toggleDeleteEventModal();
                setSelectedEvent(row);
              }}
            >
              Delete
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          {(permissions?.includes('CreateEvent') || role === 'SuperAdmin') && (
            <div className="flex space-x-2">
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleCreateEventModal}
              >
                Create Event
              </button>
            </div>
          )}
        </div>
        <div className="mt-4">
          {isLoading ? (
            <div className="w-full flex align-middle justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <TableWrapper
              title="List of Events"
              columns={columns}
              // eslint-disable-next-line no-unsafe-optional-chaining
              data={data ? (data?.data).events : []}
              //   onRowClick={(event, rowData) => navigate(`/group/${rowData.id}`)}
            />
          )}
        </div>
      </MainContent>
      <Modal
        modalOpen={createEventModalOpen}
        toggleModal={toggleCreateEventModal}
        title="Create Event"
      >
        <CreateEvent toggleModal={toggleCreateEventModal} />
      </Modal>
      <Modal
        modalOpen={editEventModalOpen}
        toggleModal={toggleEditEventModal}
        title="Edit Event"
      >
        <EditEvent
          eventData={selectedEvent}
          toggleModal={toggleEditEventModal}
        />
      </Modal>
      <Modal
        modalOpen={deleteEventModalOpen}
        toggleModal={toggleDeleteEventModal}
        title="Delete Event"
      >
        <DeleteEvent
          toggleDeleteEventModal={toggleDeleteEventModal}
          eventId={selectedEvent?.id}
        />
      </Modal>
    </>
  );
}

export default Events;
