const tinyMCEConfigs = {
  plugins:
    'save preview accordion anchor link autolink autoresize autosave charmap codesample directionality emoticons fullscreen help image insertdatetime lists advlist media pagebreak quickbars searchreplace table visualblocks visualchars wordcount',
  toolbar:
    'save restoredraft | preview help searchreplace | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | quickimage quicktable | link image media table codesample | addcomment showcomments | align lineheight ltr rtl | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
  save_enablewhendirty: true,
  link_default_target: '_blank',
  link_context_toolbar: true,
  autoresize_bottom_margin: 20,
  autoresize_overflow_padding: 10,
  min_height: 400,
  max_height: 900,
  autosave_restore_when_empty: true,
  codesample_languages: [
    { text: 'HTML/XML', value: 'markup' },
    { text: 'JavaScript', value: 'javascript' },
    { text: 'CSS', value: 'css' },
    { text: 'PHP', value: 'php' },
    { text: 'Ruby', value: 'ruby' },
    { text: 'Python', value: 'python' },
    { text: 'Java', value: 'java' },
    { text: 'C', value: 'c' },
    { text: 'C#', value: 'csharp' },
    { text: 'C++', value: 'cpp' },
    { text: 'Swift', value: 'swift' },
    { text: 'Kotlin', value: 'kotlin' },
    { text: 'TypeScript', value: 'typescript' },
    { text: 'SQL', value: 'sql' },
    { text: 'Go', value: 'go' },
    { text: 'Rust', value: 'rust' },
    { text: 'R', value: 'r' },
    { text: 'MATLAB', value: 'matlab' },
    { text: 'Shell', value: 'shell' },
    { text: 'Dart', value: 'dart' },
    { text: 'Haskell', value: 'haskell' },
    { text: 'Perl', value: 'perl' },
    { text: 'Scala', value: 'scala' },
  ],
  image_caption: true,
  image_advtab: true,
  images_file_types: 'svg jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
  a11y_advanced_options: true,
  file_picker_types: 'file image media',
  quickbars_insert_toolbar: 'quickimage quicktable | hr pagebreak',
  quickbars_image_toolbar:
    'alignleft aligncenter alignright | rotateleft rotateright | imageoptions',
};

export default tinyMCEConfigs;
