import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { useQuill } from 'react-quilljs';
import CancelIcon from '@mui/icons-material/Cancel';
import { useParams } from 'react-router-dom';
import { instructionOptions, toolbarOptions } from '../utils/editorToolbar';
import Spinner from './spinner/Spinner';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const groupAssignmentSchema = yup
  .object({
    title: yup.string().required('This is a required field'),
    week_id: yup.string(),
    purpose: yup
      .object()
      .shape({
        label: yup.string().required('Purpose is required (from label)'),
        value: yup.string().required('Purpose is required'),
      })
      .nullable()
      .required('Please select a cohort'),
    deadline: yup.string().required('This is a required field'),
    // deadline: yup.string().when('week_id', {
    //   is: (week_id) => week_id.trim().length > 0,
    //   then: yup.string().required('This is a required field'),
    // }),
  })
  .required();

const weeklyAssignmentSchema = yup
  .object({
    title: yup.string().required('This is a required field'),
    week_id: yup.string(),
  })
  .required();

function CreateAssignment({ toggleModal, weekId, newAssignmentClicked }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [body, setBody] = useState('');
  const [instructions, setInstructions] = useState('');
  const [cohortId, setCohortId] = useState('');
  const { groupId } = useParams();

  const { quill, quillRef } = useQuill({
    modules: { toolbar: toolbarOptions },
  });

  const { quill: instructionsQuill, quillRef: instructionsQuillRef } = useQuill(
    {
      modules: { toolbar: instructionOptions },
    }
  );

  const schema = weekId ? weeklyAssignmentSchema : groupAssignmentSchema;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      week_id: weekId || '',
    },
  });

  const queryClient = useQueryClient();
  const onAssignmentCreationSuccess = () => {
    toast.success('Assignment successfully created!');
    setIsSubmitting(false);
    if (!weekId) {
      toggleModal();
    }
  };
  const onAssignmentCreationFailure = () => {
    toast.error('Assignment creation failed! Please try again');
    setIsSubmitting(false);
  };

  const mutation = useMutation(
    (data) => {
      const url = weekId ? '/assignments/week' : '/group-assignments';
      return axiosInstancev2.post(url, data);
    },
    {
      onSuccess: () => {
        const queryKey = weekId
          ? ['week-assignments', weekId]
          : ['group-assignments', groupId];
        if (weekId) {
          newAssignmentClicked();
        }
        onAssignmentCreationSuccess();
        queryClient.invalidateQueries(queryKey);
      },
      onError: onAssignmentCreationFailure,
    }
  );

  const { data } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const options = data
    ? data?.data.cohorts
        .filter((cohort) => cohort.status !== 'ended')
        .map((cohort) => ({
          value: cohort.id,
          label: cohort.name,
        }))
    : [];

  const { data: purposeList, purposeLoading } = useFetchDatav2(
    ['purposes', cohortId],
    '/groups/purposes',
    {
      params: { cohort_id: cohortId },
    },
    "Couldn't get purposes. Please try again!",
    cohortId
  );

  const purposeOptions = purposeList
    ? purposeList?.data.group_purposes?.map((purpose) => ({
        value: purpose,
        label: purpose,
        cohort_id: purpose.cohort_id,
      }))
    : [];

  const isBodyEmpty = body === '<p><br></p>' || body === '';
  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setBody(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef]);

  const isInstructionsEmpty =
    instructions === '<p><br></p>' || instructions === '';
  useEffect(() => {
    if (instructionsQuill) {
      instructionsQuill.on('text-change', () => {
        setInstructions(instructionsQuill.root.innerHTML);
      });
    }
  }, [instructionsQuill, instructionsQuillRef]);

  const onSubmit = async (requestData) => {
    let requestBody;
    setIsSubmitting(true);
    if (weekId) {
      requestBody = {
        ...requestData,
        week_id: weekId,
        instructions,
        body,
      };
    } else {
      requestBody = {
        ...requestData,
        instructions,
        body,
        cohort_id: cohortId,
        group_purpose: requestData.purpose.value,
      };
    }
    mutation.mutate(requestBody, 'hrhr');
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          {...register('week_id')}
          type="text"
          id="week_id"
          placeholder=""
          hidden
          value={weekId}
        />
        <div className="grid grid-cols-2 gap-4 ">
          {!weekId && (
            <div className="flex flex-col space-y-1">
              <label htmlFor="cohort" className="text-base">
                Select Cohort
              </label>
              <Controller
                name="cohort"
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <Select
                    {...field}
                    isClearable
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    options={options}
                    onChange={(event) => {
                      setCohortId(event?.value);
                    }}
                    value={options.filter(function filterOptions(option) {
                      return option.value === cohortId;
                    })}
                  />
                )}
              />
              {!cohortId && (
                <span className="text-red-600 text-xs mt-2">
                  Please select a cohort
                </span>
              )}
            </div>
          )}
          {cohortId && (
            <div className="flex flex-col space-y-1" key={cohortId}>
              <label htmlFor="purpose" className="text-base">
                Select Purpose
              </label>
              <Controller
                name="purpose"
                control={control}
                render={({ field }) => (
                  <Select
                    key={cohortId}
                    {...field}
                    isClearable
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    options={purposeOptions}
                    isLoading={purposeLoading}
                  />
                )}
              />
              {errors.purpose && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.purpose?.value?.message}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="title" className="text-base">
              Title
            </label>
            <input
              {...register('title', { required: true })}
              type="text"
              id="title"
              placeholder="Title"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.title ? 'border-red-600' : ''
              }`}
            />
            {errors.title && (
              <span className="text-red-600 text-xs mt-2">
                {errors.title?.message}
              </span>
            )}
          </div>
          {!weekId && (
            <div className="flex flex-col space-y-1">
              <label htmlFor="deadline" className="text-base">
                Submission Deadline
              </label>
              <input
                {...register('deadline', { required: true })}
                min={new Date().toISOString().split('T')[0]}
                type="date"
                id="deadline"
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.deadline ? 'border-red-600' : ''
                }`}
              />
              {errors.deadline && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.deadline?.message}
                </span>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="lessonContent" className="text-base">
            Assignment Instructions
          </label>{' '}
          <div ref={instructionsQuillRef} />
          {isInstructionsEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter Assignment Instructions
            </span>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="lessonContent" className="text-base">
            Assignment Content
          </label>{' '}
          <div ref={quillRef} />
          {isBodyEmpty && (
            <span className="text-red-600 text-xs mt-2">
              Please Enter Assignment Content
            </span>
          )}
        </div>
        {isSubmitting ? (
          <div className="w-full flex align-middle justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex justify-between mt-8">
            {!weekId ? (
              <button
                className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
                type="button"
                onClick={toggleModal}
              >
                <CancelIcon fontSize="inherit" />
                <p>Cancel</p>
              </button>
            ) : (
              <div className="" />
            )}
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
            >
              <SaveIcon fontSize="inherit" />
              <p>Create Assignment</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default CreateAssignment;
