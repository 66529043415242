import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Editor } from '@tinymce/tinymce-react';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Spinner from './spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import tinyMCEConfigs from '../utils/constants/tinymce';

const schema = yup
  .object({
    lessonTopic: yup.string().required('Lesson topic is required'),
    lessonFile: yup
      .mixed()
      .test('fileSize', 'File size should be less than 5MB', (value) => {
        if (!value || value.length === 0) {
          return true;
        }
        return value[0]?.size <= 5000000;
      }),
  })
  .required();

function EditLesson({
  currentLessonContent,
  currentLessonTopic,
  currentLessonFileUrl,
}) {
  const { lessonId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contentIsDirty, setContentIsDirty] = useState(false);
  const [savedLessonContent, setSavedLessonContent] = useState(null);
  const queryClient = useQueryClient();
  const permissions = usePermissions();
  const role = useGetUserRole();

  const canUpdateLesson =
    permissions?.includes('UpdateLesson') || role === 'SuperAdmin';

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Initialize fields with current week details
  useEffect(() => {
    setValue('lessonTopic', currentLessonTopic);
  }, [currentLessonTopic, setValue]);

  // check if is empty
  const isEmpty =
    currentLessonContent === '<p><br></p>' || currentLessonContent === '';

  const editorRef = useRef(null);

  const saveContent = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setContentIsDirty(false);
      editorRef.current.setDirty(false);
      setSavedLessonContent(content);
    }
  };

  const onUpdateLessonSuccess = (response_data) => {
    toast.success(
      response_data?.data?.success_message || 'Lesson updated successfully'
    );
    queryClient.invalidateQueries(['lesson', lessonId]);
    setSavedLessonContent(null);
    setIsSubmitting(false);
  };

  const onUpdateLessonFailure = (error) => {
    toast.error(`Failed to update lesson. ${error?.response?.data?.error}`);
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => {
      return axiosInstancev2.put(`/week-lessons/${lessonId}`, data);
    },
    { onSuccess: onUpdateLessonSuccess, onError: onUpdateLessonFailure }
  );

  const updateLesson = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();

    formData.append('lessonTopic', data?.lessonTopic);
    if (savedLessonContent) {
      formData.append('lessonContent', savedLessonContent);
    }
    if (data?.lessonFile && data?.lessonFile[0]) {
      formData.append('lessonFile', data.lessonFile[0]);
    }

    mutate(formData);
  };

  return (
    <div className="mt-4 p-3 bg-gray-100 rounded-lg w-full">
      <form onSubmit={handleSubmit(updateLesson)} className="space-y-4">
        <div className="flex flex-col space-y-1">
          <label htmlFor="lessonTopic" className="text-base">
            Lesson Topic
          </label>
          <input
            {...register('lessonTopic')}
            type="text"
            id="lessonTopic"
            placeholder="Lesson Topic"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.lessonTopic ? 'border-red-600' : ''
            }`}
          />
          {errors.lessonTopic && (
            <span className="text-red-600 text-xs mt-2">
              {errors.lessonTopic.message}
            </span>
          )}
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div className="flex flex-col space-y-1">
            <label htmlFor="lessonFile" className="text-base">
              Select New Lesson File (max 5MB)
            </label>
            <input
              {...register('lessonFile')}
              type="file"
              id="lessonFile"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.lessonFile ? 'border-red-600' : ''
              }`}
            />
            {errors.lessonFile && (
              <span className="text-red-600 text-xs mt-2">
                {errors.lessonFile.message}
              </span>
            )}
          </div>
          {currentLessonFileUrl ? (
            <div className="flex space-x-1 justify-center items-center">
              <a href={currentLessonFileUrl}>
                <button
                  className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center mt-4"
                  type="button"
                >
                  Download Current Lesson File
                </button>
              </a>
            </div>
          ) : (
            <p>Lesson File not available</p>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <label htmlFor="lessonContent" className="text-base">
            Lesson Content
          </label>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="gpl"
            initialValue={currentLessonContent}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            onDirty={() => setContentIsDirty(true)}
            init={{
              ...tinyMCEConfigs,
              editable_root: canUpdateLesson,
              save_onsavecallback: saveContent,
            }}
          />
          {contentIsDirty && <p>You have unsaved content!</p>}
        </div>
        {isSubmitting ? (
          <div className="grid items-center justify-center">
            <Spinner />
          </div>
        ) : (
          canUpdateLesson && (
            <div className="flex justify-between mt-4">
              <button
                type="submit"
                className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center ${
                  contentIsDirty || isEmpty || Object.keys(errors).length > 0
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-claret-500'
                }`}
                disabled={
                  contentIsDirty || isEmpty || Object.keys(errors).length > 0
                }
              >
                <SaveIcon fontSize="inherit" />
                <p>Update Lesson</p>
              </button>
            </div>
          )
        )}
      </form>
    </div>
  );
}

export default EditLesson;
