/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import GroupIcon from '@mui/icons-material/Group';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import { Avatar } from '@mui/material';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import UpdateUserDetails from '../components/UpdateUserDetails';
import Modal from '../components/Modal';
import UpdateProfilePic from '../components/UpdateProfilePic';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import useGetLoggedInUserDetails from '../hooks/useGetLoggedInUserDetails';
import TableWrapper from '../utils/TableWrapper';

const columns = [
  { title: 'Action', field: 'log_type' },
  {
    title: 'Perfomed On',
    render: (row) => (
      <p>{`${new Date(row.logged_at).toLocaleString('en-us', {
        dateStyle: 'full',
        timeStyle: 'medium',
      })}`}</p>
    ),
  },
];

function Profile() {
  const { userId } = useParams();
  const [oneUser, setOneUser] = useState({});
  const queryClient = useQueryClient();
  const loggedInUser = useGetLoggedInUserDetails();

  // TODO: configure pagination with the table
  const fetchUserDetails = async () => {
    const data = await axiosInstancev2.get(`/users/administrator/${userId}`);
    return data;
  };

  const onFetchUserDetailsFailure = () => {
    toast.error("Couldn't fetch user details. Please try again!");
  };

  const { data, isLoading } = useQuery(
    ['user-details', userId],
    fetchUserDetails,
    {
      onSuccess: (user) => {
        setOneUser(user.data.data);
      },
      onError: onFetchUserDetailsFailure,
      initialData: () => {
        return setOneUser(
          queryClient
            .getQueryData(['users'])
            ?.data?.data?.find((user) => user.id === userId)
        );
      },
      enabled: !!userId,
      staleTime: 3600 * 10000,
      cacheTime: 3600 * 10000,
    }
  );

  const [modalOpen, setModalOpen] = useState(false);

  const { data: userLogs } = useFetchAnalytics(
    ['activity-logs', userId],
    `/admin-activity-logs/${userId}`,
    {},
    "Couldn't get activity logs. Please try again",
    loggedInUser.role === 'SuperAdmin' || loggedInUser.role === 'Admin'
  );

  const toggleProfileModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex w-full justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/users"
            className="flex items-center"
          >
            <GroupIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Users</p>
          </NavLink>
        </Breadcrumbs>
        {/* <button
          type="button"
          className="bg-claret-500 text-white text-sm py-1 px-3 rounded-md cursor-pointer uppercase font-medium"
          //   onClick={toggleModal}
        > 
          + Create New User
        </button> */}
      </div>
      {isLoading ? (
        <div className="bg-white mt-4 flex justify-center items-center align-middle">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="bg-white px-4 py-5 mt-4 rounded-lg w-full flex align-middle items-start space-x-5 ">
            <Tooltip
              title={<p className="capitalize text-sm">update picture</p>}
              className="text-lg"
            >
              <Avatar
                sx={{ height: 150, width: 150 }}
                src={data?.data?.administrator?.profile_image}
                className="bg-center ring-2 ring-claret-500 cursor-pointer hover:bg-blend-overlay bg-opacity-"
                onClick={toggleProfileModal}
              >
                <p className="uppercase text-4xl">
                  {data?.data?.administrator?.firstname[0]}
                  {data?.data?.administrator?.lastname[0]}
                </p>
              </Avatar>
            </Tooltip>
            <div className="space-y-0 pr-2">
              <p className="text-xl capitalize font-semibold tracking-wide fon">
                {data?.data?.administrator?.firstname}{' '}
                {data?.data?.administrator?.lastname}
              </p>
              <p className="capitalize text-gray-600">
                {data?.data?.administrator?.role}
              </p>
              <p className="text-gray-700">
                {data?.data?.administrator?.profile_desc}
              </p>
            </div>
          </div>
          <UpdateUserDetails userDetails={data?.data?.administrator} />
          {loggedInUser.role === 'SuperAdmin' ||
          loggedInUser.role === 'Admin' ? (
            <TableWrapper
              title={`Activity logs for ${data?.data?.administrator?.firstname} ${data?.data?.administrator?.lastname}`}
              columns={columns}
              // eslint-disable-next-line no-unsafe-optional-chaining
              data={userLogs?.data?.admin_activity_logs || []}
            />
          ) : null}
        </>
      )}

      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleProfileModal}
        title="Update Profile Picture"
      >
        <UpdateProfilePic
          userDetails={data?.data}
          toggleModal={toggleProfileModal}
        />
      </Modal>
    </MainContent>
  );
}

export default Profile;
