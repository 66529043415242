/* eslint-disable no-shadow */
import React, { useState } from 'react';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EditIcon from '@mui/icons-material/Edit';
import Select from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Poll from '@mui/icons-material/Poll';
import { useNavigate, useParams } from 'react-router';
import toast from 'react-hot-toast';
import Modal from './Modal';
import AddWeek from './AddWeek';
import EditWeek from './EditWeek';
import Spinner from './spinner/Spinner';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import useFetchDatav2 from '../hooks/useFetchDatav2';

function Curriculum({ weeks, moduleName }) {
  const [clicked, setClicked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [editWeekModalOpen, setEditWeekModalOpen] = useState(false);
  const [removeWeekModalOpen, setRemoveWeekModalOpen] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [weekId, setWeekId] = useState('');
  const permissions = usePermissions();
  const role = useGetUserRole();
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const [selectedMoreActionOption, setSelectedMoreActionOption] =
    useState(null);

  const moreActionsOptions = [
    { value: 'addLesson', label: 'Add Lesson' },
    { value: 'addQuiz', label: 'Add Quiz' },
    { value: 'addAssignment', label: 'Add Assignment' },
    { value: 'addSurvey', label: 'Add Survey' },
  ];

  const handleMoreActionsDropdown = (selectedOption, weekDetails) => {
    if (!selectedOption) return;

    switch (selectedOption.value) {
      case 'addLesson':
        if (permissions?.includes('CreateLesson') || role === 'SuperAdmin') {
          navigate(
            `/courses/modules/week-${weekDetails?.weekName}/${weekDetails?.id}/lessons/create`
          );
        } else {
          toast.error("You don't have permission to add lessons.");
        }
        break;
      case 'addQuiz':
        if (permissions?.includes('CreateQuiz') || role === 'SuperAdmin') {
          navigate(
            `/courses/modules/week-${weekDetails?.weekName}/${weekDetails?.id}/quizzes`
          );
        } else {
          toast.error("You don't have permission to add quizzes.");
        }
        break;
      case 'addAssignment':
        if (
          permissions?.includes('CreateAssignment') ||
          role === 'SuperAdmin'
        ) {
          navigate(
            `/courses/modules/week-${weekDetails?.weekName}/${weekDetails?.id}/assignments/create`
          );
        } else {
          toast.error("You don't have permission to add assignments.");
        }
        break;
      case 'addSurvey':
        if (permissions?.includes('CreateSurvey') || role === 'SuperAdmin') {
          navigate(
            `/courses/modules/week-${weekDetails?.weekName}/${weekDetails?.id}/surveys`
          );
        } else {
          toast.error("You don't have permission to add surveys.");
        }
        break;
      default:
        break;
    }
    setSelectedMoreActionOption(null); // Reset selection after action
  };

  const toggle = (index) => {
    // setLessons([]);
    if (clicked === index) {
      setClicked(null);
      return;
    }

    setClicked(index);
  };

  const toggleModal = () => {
    if (modalOpen) {
      setSelectedWeek(null);
    }
    setModalOpen(!modalOpen);
  };

  const fetchLessonsByWeek = async (weekId) => {
    const data = await axiosInstancev2.get('/week-lessons', {
      params: { weekId },
    });
    return data;
  };

  const {
    data: weekLessons,
    isLoading,
    refetch,
  } = useQuery(['week-lessons', weekId], () => fetchLessonsByWeek(weekId), {
    staleTime: 3600 * 1000,
    cacheTime: 3600 * 1000,
    enabled: !!weekId,
  });

  const fetchAssignmentsByWeek = async (weekId) => {
    const data = await axiosInstancev2.get(`assignments/week/${weekId}`);
    return data;
  };

  const {
    data: weekAssignments,
    isLoading: assignmentsLoading,
    refetch: refetchAssignments,
  } = useQuery(
    ['week-assignments', weekId],
    () => fetchAssignmentsByWeek(weekId),
    {
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
      enabled: !!weekId,
    }
  );

  // const fetchSurveysByWeek = async () => {
  //   const data = await axiosInstancev2.get(`surveys`, {
  //     params: { week_id: weekId },
  //   });
  //   return data;
  // };

  const { data: surveyData, isLoading: surveysLoading } = useFetchDatav2(
    ['surveys', weekId],
    '/surveys',
    {
      params: { week_id: weekId },
    },
    "Couldn't fetch surveys! Please try again",
    !!weekId
  );

  const getLessonsByWeek = (index) => {
    toggle(index);
    refetch();
    refetchAssignments();
  };

  const toggleEditWeekModal = (week) => {
    if (editWeekModalOpen) {
      getLessonsByWeek(week?.id);
      setSelectedWeek(null);
    }
    setEditWeekModalOpen(!editWeekModalOpen);
  };

  const toggleRemoveWeekModal = (week) => {
    if (removeWeekModalOpen) {
      getLessonsByWeek(week?.id);
      setSelectedWeek(null);
    }
    setRemoveWeekModalOpen(!removeWeekModalOpen);
  };

  // delete week
  const queryClient = useQueryClient();
  const onLessonDeletionSuccess = () => {
    toast.success('Week successfully deleted!');
    toggleRemoveWeekModal();
  };
  const onLessonDeletionFailure = () => {
    toast.error('Week Deletion failed! Please try again');
  };

  const { mutate, isLoading: deleteWeekLoading } = useMutation(
    () => {
      return axiosInstancev2.delete(`/module-weeks/${weekId}`);
    },
    {
      onSuccess: () => {
        onLessonDeletionSuccess();
        queryClient.invalidateQueries(['module-weeks']);
      },
      onError: onLessonDeletionFailure,
    }
  );

  return (
    <div className="space-y-2">
      <div className=" flex justify-end">
        {(permissions?.includes('CreateWeek') || role === 'SuperAdmin') && (
          <button
            type="button"
            className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
            onClick={toggleModal}
          >
            + Add Week
          </button>
        )}
      </div>
      {!weeks || (weeks && weeks.length < 1) ? (
        <p className="text-xl text-center mt-4">No weeks added yet</p>
      ) : (
        weeks?.map((week, i) => (
          <div key={week.id}>
            <div className="font-normal space-x-5 flex border-2 p-2">
              {clicked === i ? (
                <p
                  role="presentation"
                  className="text-[#00999E]"
                  onClick={() => toggle(i)}
                >
                  -
                </p>
              ) : (
                <p
                  role="presentation"
                  onClick={() => {
                    setWeekId(week.id);
                    getLessonsByWeek(i);
                  }}
                >
                  +
                </p>
              )}
              <div className=" flex justify-between w-full">
                {/* <p>Week {i + 1}</p> */}
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setWeekId(week.id);
                    getLessonsByWeek(i);
                  }}
                >
                  <p>{week.weekName}</p>
                </div>
                <div className=" flex space-x-4">
                  {(permissions?.includes('UpdateWeek') ||
                    role === 'SuperAdmin') && (
                    <button
                      type="button"
                      className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer flex items-center space-x-1"
                      onClick={() => {
                        setSelectedWeek(week);
                        toggleEditWeekModal(week);
                      }}
                    >
                      <EditIcon fontSize="inherit" /> <p>Edit Week</p>
                    </button>
                  )}
                  {(permissions?.includes('DeleteWeek') ||
                    role === 'SuperAdmin') && (
                    <button
                      type="button"
                      className="text-white bg-claret-500 text-sm py-1 px-5 rounded-md cursor-pointer  flex items-center space-x-1"
                      onClick={() => {
                        setWeekId(week.id);
                        setSelectedWeek(week);
                        toggleRemoveWeekModal();
                      }}
                    >
                      <DeleteIcon fontSize="inherit" />
                      <p>Remove Week</p>
                    </button>
                  )}
                  <div className="flex items-center space-x-2">
                    <Select
                      value={selectedMoreActionOption}
                      className="react-dropdown text-claret-500 rounded-md border border-claret-500"
                      classNamePrefix="dropdown"
                      options={moreActionsOptions}
                      isClearable
                      isSearchable={false}
                      onChange={(selectedOption) => {
                        setSelectedMoreActionOption(selectedOption);
                        handleMoreActionsDropdown(selectedOption, week);
                      }}
                      placeholder="More Actions"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${clicked === i ? 'block' : 'hidden'}`}>
              {isLoading && (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              )}
              {!isLoading &&
              weekLessons &&
              weekLessons?.data?.lessons?.length > 0
                ? weekLessons?.data?.lessons?.map((lesson, i) => (
                    <div
                      onClick={() =>
                        navigate(
                          `/courses/modules/${moduleId}/week/${weekId}/lesson/${lesson.id}`
                        )
                      }
                      key={lesson.id}
                      className={`flex space-x-5 py-4 cursor-pointer px-2 ${
                        (i + 1) % 2 === 0 ? '' : 'bg-[#D0EFED]'
                      }`}
                    >
                      <LibraryBooksIcon className={`text-[#00999E] `} />
                      <p>
                        Lesson {i + 1} : {lesson.lessonTopic}
                      </p>
                    </div>
                  ))
                : !isLoading && (
                    <p className="text-xl text-center mt-4">
                      No lessons uploaded yet.
                    </p>
                  )}
              {!assignmentsLoading &&
              weekAssignments &&
              weekAssignments?.data?.assignments?.length > 0
                ? weekAssignments?.data?.assignments?.map((assignment, i) => (
                    <div
                      onClick={() => navigate(`/assignment/${assignment.id}`)}
                      key={assignment.id}
                      className={`flex space-x-5 py-4 cursor-pointer px-2 ${
                        (i + 1) % 2 === 0 ? '' : 'bg-[#D0EFED]'
                      }`}
                    >
                      <AssignmentOutlinedIcon className={`text-[#00999E] `} />
                      <p>
                        Asssignment {i + 1} : {assignment.title}
                      </p>
                    </div>
                  ))
                : !isLoading && (
                    <p className="text-xl text-center mt-4">{null}</p>
                  )}
              {(permissions?.includes('CreateSurvey') ||
                role === 'SuperAdmin') && (
                <div className="">
                  {!assignmentsLoading &&
                  !assignmentsLoading &&
                  !surveysLoading &&
                  surveyData?.data?.surveys?.length > 0
                    ? surveyData?.data?.surveys?.map((survey, i) => (
                        <div
                          onClick={() =>
                            navigate(`/survey/${survey.name}/${survey.id}`)
                          }
                          key={survey.id}
                          className={`flex space-x-5 py-4 cursor-pointer px-2 ${
                            (i + 1) % 2 === 0 ? '' : 'bg-[#D0EFED]'
                          }`}
                        >
                          <Poll className={`text-[#00999E] `} />
                          <p>
                            Suvey {i + 1} : {survey.name}
                          </p>
                        </div>
                      ))
                    : !isLoading && (
                        <p className="text-xl text-center mt-4">{null}</p>
                      )}
                </div>
              )}
            </div>
          </div>
        ))
      )}
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Add New Week"
      >
        <AddWeek toggleModal={toggleModal} />
      </Modal>
      <Modal
        modalOpen={editWeekModalOpen}
        toggleModal={toggleEditWeekModal}
        title="Edit Week"
      >
        <EditWeek
          week={selectedWeek}
          lessons={weekLessons?.data?.lessons || []}
          getLessonsByWeek={refetch}
          toggleModal={toggleEditWeekModal}
        />
      </Modal>
      <Modal
        modalOpen={removeWeekModalOpen}
        toggleModal={toggleRemoveWeekModal}
        title="Remove Week"
      >
        <p>
          Are you sure you want to remove{' '}
          <span className="capitalize font-medium">
            {selectedWeek?.weekName}
          </span>{' '}
          of <span className="capitalize font-medium">{moduleName}</span>
        </p>
        {deleteWeekLoading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className=" flex space-x-4 mt-8 justify-between">
            <button
              type="button"
              className="text-claret-500 border-[1px] border-claret-500 bg-white text-sm py-1 px-5 rounded-md cursor-pointer"
              onClick={toggleRemoveWeekModal}
            >
              <CancelIcon fontSize="inherit" /> cancel
            </button>
            <button
              type="button"
              className="bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center"
              onClick={mutate}
            >
              <DeleteIcon />
              <p>Remove Week</p>
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Curriculum;
