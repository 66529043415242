import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SchoolIcon from '@mui/icons-material/School';
import LoginIcon from '@mui/icons-material/Login';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import Calendar from '../components/calendar/Calendar';
import AnalyticsCard from '../components/AnalyticsCard';

function AnalyticsDashBoard() {
  const analyticsCards = [
    {
      id: 1,
      name: 'Cohort Logins',
      description:
        'Login stats for students, showing activity and engagement by cohort.',
      icon: <LoginIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/cohort-logins',
    },
    {
      id: 2,
      name: 'Quiz Analytics',
      description:
        'Insights into quiz submissions, performance metrics by gender and cohort.',
      icon: <AssessmentIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/quiz-analytics',
    },
    {
      id: 3,
      name: 'Weekly Assignment Analytics',
      description:
        'Insights into weekly assignment submissions, performance metrics by cohort.',
      icon: <AssessmentIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/assignment-analytics',
    },
    {
      id: 4,
      name: 'Scholarship Modules Student Progress',
      description:
        'Analysis of learner progression across scholarship modules and courses by cohort.',
      icon: <SchoolIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/scholarship-modules-students-progress',
    },
    {
      id: 5,
      name: 'Paid Modules Student Progress',
      description:
        'Analysis of learner progression across paid modules and courses per paid cohort.',
      icon: <SchoolIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/paid-modules-students-progress',
    },

    {
      id: 6,
      name: 'Paid Modules Analytics',
      description:
        'Analytics of paid modules, student enrollment, and revenue generated.',
      icon: <MonetizationOnIcon style={{ height: 80, width: 80 }} />,
      componentLink: '/analytics/paid-modules',
    },
  ];

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 mt-4">
          {analyticsCards?.map((card) => (
            <AnalyticsCard
              key={card.id}
              cardName={card.name}
              cardDescription={card.description}
              componentLink={card.componentLink}
            >
              {card.icon}
            </AnalyticsCard>
          ))}
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default AnalyticsDashBoard;
