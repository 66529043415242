import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Select from 'react-select';
import formatISO9075 from 'date-fns/formatISO9075';
import Spinner from './spinner/Spinner';
import useFetchData from '../hooks/useFetchData';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';

const schema = yup
  .object({
    title: yup.string().required('This is a required field'),
    startDate: yup
      .date('Please enter a valid date')
      .required('Please enter a valid date')
      .min(new Date(), 'Start date cannot be in the past!'),
    endDate: yup.date('Please enter a valid date').when(
      'startDate',
      // eslint-disable-next-line no-shadow
      (startDate, yup) =>
        startDate && yup.min(startDate, 'End date cannot be before start date')
    ),
    description: yup.string().required('This is a required field'),
    meetingLink: yup.string().url().required().label('Event Link'),
  })
  .required();

function EditTimetableEvent({ appointmentData, toggleModal }) {
  const [cohortId, setCohortId] = useState(appointmentData?.cohortId);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // get cohorts
  const { data: cohorts } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );
  const cohortOptions = cohorts
    ? cohorts?.data.data
        ?.filter((cohort) => cohort.status !== 'ended')
        .map((cohort) => ({
          value: cohort.id,
          label: `${cohort.name} - ${cohort.no_of_students || 0} students`,
        }))
    : [];

  const queryClient = useQueryClient();
  const onTimetableEventUpdateSuccess = () => {
    toast.success('Timetable event successfully updated!');
    toggleModal();
  };
  const onTimetableEventUpdateFailure = () => {
    toast.error('Timetable event update failed! Please try again');
  };
  const { mutate, isLoading } = useMutation(
    (data) => {
      return axiosInstancev2.put(
        `timetable-events/${appointmentData?.id}`,
        data
      );
    },
    {
      onSuccess: () => {
        onTimetableEventUpdateSuccess();
        queryClient.invalidateQueries(['timetable-events']);
      },
      onError: onTimetableEventUpdateFailure,
    }
  );

  const onSubmit = async (requestData) => {
    mutate({
      ...requestData,
      cohortId,
      startDate: new Date(requestData.startDate).toISOString(),
      endDate: new Date(requestData.endDate).toISOString(),
    });
  };

  return (
    <form
      className="text-gray-700 space-y-2 mx-auto p-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid grid-cols-2 gap-4 ">
        <div className="flex flex-col space-y-1">
          <label htmlFor="cohort" className="text-base">
            Select Cohort
          </label>
          <Select
            defaultValue={cohortOptions?.filter(
              (option) => option.value === cohortId
            )}
            placeholder="Select a cohort"
            isClearable
            className="react-dropdown"
            classNamePrefix="dropdown"
            options={cohortOptions}
            onChange={(event) => {
              setCohortId(event?.value);
            }}
            value={cohortOptions?.filter((option) => option.value === cohortId)}
          />
          {!cohortId && (
            <span className="text-red-600  mt-2">Please select a cohort</span>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-2">
        <div className="flex flex-col mb-2">
          <label htmlFor="startDate" className=" text-mine-shaft-500 mb-1 pl-1">
            Start Date
          </label>
          <input
            defaultValue={formatISO9075(new Date(appointmentData?.startDate))}
            id="startDate"
            {...register('startDate')}
            className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
              errors.startDate ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="datetime-local"
            placeholder="Start Date"
            min={new Date().toISOString().split('T')[0]}
          />
          {errors.startDate && (
            <span className="text-red-600  mt-1">
              {errors.startDate?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col mb-2">
          <label htmlFor="endDate" className=" text-mine-shaft-500 mb-1 pl-1">
            End Date
          </label>
          <input
            defaultValue={formatISO9075(new Date(appointmentData?.endDate))}
            id="endDate"
            {...register('endDate')}
            className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
              errors.endDate ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="datetime-local"
            placeholder="End Date"
            min={new Date().toISOString()}
          />
          {errors.endDate && (
            <span className="text-red-600  mt-1">
              {errors.endDate?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col col-span-2 mb-5">
          <label htmlFor="title" className=" text-mine-shaft-500 mb-1 pl-1">
            Event Title
          </label>
          <textarea
            rows={3}
            defaultValue={appointmentData?.title}
            id="title"
            {...register('title')}
            className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
              errors.title ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="text"
            placeholder="Event Title"
          />
          {errors.title && (
            <span className="text-red-600  mt-1">{errors.title?.message}</span>
          )}
        </div>
        <div className="flex flex-col col-span-2 mb-5">
          <label
            htmlFor="meetingLink"
            className=" text-mine-shaft-500 mb-1 pl-1"
          >
            Event Link
          </label>
          <input
            id="meetingLink"
            defaultValue={appointmentData?.meetingLink}
            {...register('meetingLink')}
            className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
              errors.meetingLink ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="text"
            placeholder="Event Title"
          />
          {errors.meetingLink && (
            <span className="text-red-600  mt-1">
              {errors.meetingLink?.message}
            </span>
          )}
        </div>
        <div className="flex flex-col col-span-2 mb-5">
          <label
            htmlFor="description"
            className=" text-mine-shaft-500 mb-1 pl-1"
          >
            Event Description
          </label>
          <textarea
            defaultValue={appointmentData?.description}
            rows={8}
            id="description"
            {...register('description')}
            className={`border border-silver-500 rounded-md py-2 pl-3  focus:outline-gray-600 ${
              errors.description ? 'border-red-600 focus:outline-red-600' : ''
            }`}
            type="text"
            placeholder="Event Description"
          />
          {errors.description && (
            <span className="text-red-600  mt-1">
              {errors.description?.message}
            </span>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="w-full flex align-middle justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className=" flex justify-between mt-8">
          <button
            className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
            type="button"
            onClick={toggleModal}
          >
            <CancelIcon fontSize="inherit" />
            <p>Cancel</p>
          </button>
          <button
            className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
            type="submit"
          >
            <SaveIcon fontSize="inherit" />
            <p>Update Timetable Event</p>
          </button>
        </div>
      )}
    </form>
  );
}

export default EditTimetableEvent;
