import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Select from 'react-select';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import usePermissions from '../hooks/usePermissions';
import Schedule from '../components/Schedule';
import Spinner from '../components/spinner/Spinner';
import TimetableEvents from '../components/TimetableEvents';
import CreateTimetableEvent from '../components/CreateTimetableEvent';
import Modal from '../components/Modal';
import useFetchData from '../hooks/useFetchData';
import useFetchDatav2 from '../hooks/useFetchDatav2';

function Timetable() {
  const permissions = usePermissions();
  const role = useGetUserRole();
  const [modalOpen, setModalOpen] = useState(false);
  const [cohortId, setCohortId] = useState('');

  const [showTimetable, setShowTimeTable] = useState(true);

  const toggleTimeTable = () => {
    setShowTimeTable(!showTimetable);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { data: cohorts } = useFetchDatav2(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohorts
    ? cohorts?.data?.cohorts
        ?.filter((cohort) => cohort.status !== 'ended')
        .map((cohort) => ({
          value: cohort.id,
          label: `${cohort.name} - ${cohort.no_of_students || 0} students`,
        }))
    : [];

  const { data, isLoading } = useFetchData(
    ['timetable-events', cohortId],
    '/timetable-events',
    {
      params: { cohortId },
    },
    "Couldn't get cohort events. Please try again!",
    true
  );

  return (
    <>
      <MainContent full>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
          </Breadcrumbs>
          <div className=" flex space-x-2">
            {(permissions?.includes('CreateTimetableEvent') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleModal}
              >
                Create Timetable Event
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center align-middle mt-4 bg-white p-2 gap-4 rounded-lg">
          <div className="flex flex-col space-y-1 w-[20rem]">
            <label htmlFor="cohort" className="text-base">
              Select Cohort
            </label>
            <Select
              placeholder="Select a cohort"
              isClearable
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
              onChange={(event) => {
                setCohortId(event?.value);
              }}
              value={cohortOptions?.filter(
                (option) => option.value === cohortId
              )}
            />
          </div>
          <div className="space-x-2">
            {(permissions?.includes('CreateGroupAssignment') ||
              role === 'SuperAdmin') && (
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleTimeTable}
              >
                {showTimetable ? 'Show Table of Events' : 'Show Timetable'}
              </button>
            )}
          </div>
        </div>
        {isLoading && (
          <div className="mt-5 bg-white py-5">
            <Spinner />
          </div>
        )}
        {!isLoading && showTimetable && (
          <Schedule
            appointments={data?.data?.timetable_events?.map((event) => ({
              ...event,
              startDate: new Date(event.startDate),
              endDate: new Date(event.endDate),
            }))}
            role={role}
            permissions={permissions}
          />
        )}
        {!isLoading && !showTimetable && (
          <TimetableEvents
            timetableEvents={data?.data?.timetable_events || []}
          />
        )}
      </MainContent>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Create Timetable Event"
      >
        <CreateTimetableEvent toggleModal={toggleModal} />
      </Modal>
    </>
  );
}

export default Timetable;
