import React, { useState, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Editor } from '@tinymce/tinymce-react';
import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import tinyMCEConfigs from '../utils/constants/tinymce';

const schema = yup
  .object({
    assignmentTitle: yup.string().required('assignment title is required'),
  })
  .required();

function CreateWeekAssignment() {
  const navigate = useNavigate();
  const { weekId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bodyContentIsDirty, setBodyContentIsDirty] = useState(false);
  const [instructionContentIsDirty, setInstructionsContentIsDirty] =
    useState(false);
  const [assignmentBody, setAssignmentBody] = useState('');
  const [assignmentInstructions, setAssignmentInstructions] = useState('');
  const queryClient = useQueryClient();

  const permissions = usePermissions();
  const role = useGetUserRole();

  const canCreateAssignment =
    permissions?.includes('CreateAssignment') || role === 'SuperAdmin';

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // check if is empty

  const isBodyEmpty = assignmentBody === '<p><br></p>' || assignmentBody === '';

  const isInstructionsEmpty =
    assignmentInstructions === '<p><br></p>' || assignmentInstructions === '';

  const instructionEditorRef = useRef(null);
  const bodyEditorRef = useRef(null);

  const saveBodyContent = () => {
    if (bodyEditorRef.current) {
      const content = bodyEditorRef.current.getContent();
      setBodyContentIsDirty(false);
      bodyEditorRef.current.setDirty(false);
      setAssignmentBody(content);
    }
  };

  const saveInstructionsContent = () => {
    if (instructionEditorRef.current) {
      const content = instructionEditorRef.current.getContent();
      setInstructionsContentIsDirty(false);
      instructionEditorRef.current.setDirty(false);
      setAssignmentInstructions(content);
    }
  };

  const onCreateWeekAssignmentSuccess = (response_data) => {
    toast.success(
      response_data?.data?.success_message ||
        'Week assignment created successfully'
    );
    queryClient.invalidateQueries(['week-assignments', weekId]);
    setAssignmentBody('');
    setAssignmentInstructions('');
    setIsSubmitting(false);
    reset();
  };

  const onCreateWeekAssignmentFailure = (error) => {
    toast.error(
      `Failed to create week assignment. ${error?.response?.data?.error}`
    );
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => {
      return axiosInstancev2.post('/assignments/week', data);
    },
    {
      onSuccess: onCreateWeekAssignmentSuccess,
      onError: onCreateWeekAssignmentFailure,
    }
  );

  const createWeekAssignment = async (data) => {
    setIsSubmitting(true);

    const requestData = {
      title: data?.assignmentTitle,
      week_id: weekId,
      body: assignmentBody,
      instructions: assignmentInstructions,
    };

    mutate(requestData);
  };

  const buttonDisabled =
    instructionContentIsDirty ||
    bodyContentIsDirty ||
    isBodyEmpty ||
    isInstructionsEmpty ||
    Object.keys(errors).length > 0;

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/courses"
            className="flex items-center"
          >
            <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Courses</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-2)}
            to={() => navigate(-2)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Modules</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-1)}
            to={() => navigate(-1)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Module Overview</p>
          </NavLink>
        </Breadcrumbs>
      </div>
      <div className="mt-4 p-3 bg-white rounded-lg">
        <form
          onSubmit={handleSubmit(createWeekAssignment)}
          className="space-y-4"
        >
          <div className="flex flex-col space-y-1">
            <label htmlFor="assignmentTitle" className="text-base">
              Assignment Title
            </label>
            <input
              {...register('assignmentTitle')}
              type="text"
              id="assignmentTitle"
              placeholder="Assignment Title"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.assignmentTitle ? 'border-red-600' : ''
              }`}
            />
            {errors.assignmentTitle && (
              <span className="text-red-600 text-xs mt-2">
                {errors.assignmentTitle.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="assignmentInstructions" className="text-base">
              Assignment Instructions
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={assignmentInstructions}
              onInit={(evt, editor) => {
                instructionEditorRef.current = editor;
              }}
              onDirty={() => setInstructionsContentIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                editable_root: canCreateAssignment,
                save_onsavecallback: saveInstructionsContent,
              }}
            />
            {instructionContentIsDirty && (
              <p>You have unsaved assignment instructions content!</p>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="assignmentBody" className="text-base">
              Assignment Body
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={assignmentBody}
              onInit={(evt, editor) => {
                bodyEditorRef.current = editor;
              }}
              onDirty={() => setBodyContentIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                editable_root: canCreateAssignment,
                save_onsavecallback: saveBodyContent,
              }}
            />
            {bodyContentIsDirty && (
              <p>You have unsaved assignment body content!</p>
            )}
          </div>
          {isSubmitting ? (
            <div className="grid items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="flex justify-between mt-4">
              <button
                type="submit"
                className={`p-2 rounded-md text-white text-sm space-x-2 flex items-center ${
                  buttonDisabled
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#57C13E]'
                }`}
                disabled={buttonDisabled}
              >
                <SaveIcon fontSize="inherit" />
                <p>Create Week Assignment</p>
              </button>
            </div>
          )}
        </form>
      </div>
    </MainContent>
  );
}

export default CreateWeekAssignment;
