import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TableWrapper from '../utils/TableWrapper';
import Spinner from './spinner/Spinner';
import Modal from './Modal';
import RemoveGroupMember from './RemoveGroupMember';
import usePermissions from '../hooks/usePermissions';
import useFetchData from '../hooks/useFetchData';

function GroupMembers() {
  const [memberDetails, setMemberDetails] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const permissions = usePermissions();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const isValidDate = (value) => {
    const date = new Date(value);
    return date.toString() !== 'Invalid Date' && !Number.isNaN(date);
  };

  const datesDifferByDaysOrLess = (date1, date2, numberOfDays) => {
    if (!isValidDate(date1) || !isValidDate(date2)) return 0;

    const diffInMs = Math.abs(date1 - date2);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return diffInDays <= numberOfDays;
  };

  const columns = [
    {
      title: 'First Name',
      field: 'student.firstname',
      render: (row) => (
        <p className="capitalize">{row?.student?.firstname.toLowerCase()}</p>
      ),
    },
    {
      title: 'Last Name',
      field: 'user.lastname',
      render: (row) => (
        <p className="capitalize">{row?.student?.lastname.toLowerCase()}</p>
      ),
    },
    {
      title: 'Email',
      field: 'user.email',
      render: (row) => <p>{row?.student?.email}</p>,
    },
    {
      title: 'Nationality',
      field: 'user.country',
      render: (row) => <p>{row?.student?.country}</p>,
    },
    {
      title: 'Last login',
      field: 'user.last_login',
      render: (row) => {
        const currentTime = new Date();
        const lastLoginDate = new Date(row?.student?.last_login);
        let bgColorClass = 'bg-red-400';
        if (datesDifferByDaysOrLess(lastLoginDate, currentTime, 7)) {
          bgColorClass = 'bg-green-400';
        } else if (datesDifferByDaysOrLess(lastLoginDate, currentTime, 14)) {
          bgColorClass = 'bg-orange-400';
        }
        return (
          <p
            className={`${bgColorClass} text-center py-1 rounded-md capitalize font-medium`}
          >
            {isValidDate(row?.student?.last_login)
              ? lastLoginDate
                  .toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })
                  .replace(',', '')
              : 'Never'}
          </p>
        );
      },
    },
    {
      title: 'Action',
      field: 'removeUser',
      hidden: !permissions?.includes('DeleteGroupMember'),
      render: (row) => (
        <button
          className="bg-claret-500 text-white px-6 py-1 rounded-md"
          type="button"
          onClick={() => {
            toggleModal();
            setMemberDetails(row);
          }}
        >
          Remove
        </button>
      ),
    },
  ];

  const { groupId, groupName } = useParams();

  const { data, isLoading } = useFetchData(
    ['group-members', groupId],
    '/group-members',
    {
      params: { group_id: groupId },
    },
    "Couldn't get group members. Please try again!",
    true
  );

  const parsedData = data ? data?.data?.group_members : [];

  return (
    <>
      <div className="mt-5">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <TableWrapper title={groupName} columns={columns} data={parsedData} />
        )}
      </div>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Remove Member From Group"
      >
        <RemoveGroupMember
          memberDetails={memberDetails}
          toggleModal={toggleModal}
          groupId={groupId}
        />
      </Modal>
    </>
  );
}

export default GroupMembers;
