import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import Course from '../components/Course';
import CreateCourseOverview from '../components/CreateCourseOverview';
import Modal from '../components/Modal';
import RightBar from '../components/RightBar';
import Spinner from '../components/spinner/Spinner';
import Calendar from '../components/calendar/Calendar';
import usePermissions from '../hooks/usePermissions';
import MainContent from '../components/MainContent';
import useGetUserRole from '../hooks/useGetUserRole';
import useFetchDatav2 from '../hooks/useFetchDatav2';
// import useFetchData from '../hooks/useFetchData';

function Modules() {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const permissions = usePermissions();
  const role = useGetUserRole();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { data, isLoading } = useFetchDatav2(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't get course modules. Please try again.",
    true
  );

  return (
    <>
      <MainContent>
        <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
          {/* <p>Home > Courses > Modules </p> */}
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/courses"
              className="flex items-center"
            >
              <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Courses</p>
            </NavLink>
          </Breadcrumbs>
          {(permissions?.includes('CreateModule') || role === 'SuperAdmin') && (
            <button
              type="button"
              className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
              // onClick={toggleModal}
              onClick={() => navigate(`/courses/${courseId}/addmodule`)}
            >
              Add Module
            </button>
          )}
        </div>
        {isLoading ? (
          <div className="grid items-center justify-center my-auto mt-10">
            <Spinner />
          </div>
        ) : (
          <div className="px-2 py-5 rounded-lg grid grid-cols-4 gap-4">
            {!isLoading && !data?.data?.course_modules ? (
              <p className="text-center">No Modules Added Yet</p>
            ) : (
              data?.data?.course_modules?.map((module) => (
                <div
                  key={module.id}
                  className="cursor-pointer hover:scale-110 ease-in-out duration-300"
                  onClick={() => navigate(`/module/${module.id}`)}
                >
                  <div className="opacity-0 h-0 group-hover:opacity-100 group-hover:h-full">
                    This Is The Overlay
                  </div>
                  <Course
                    name={module.moduleName}
                    description={module.moduleDescription}
                    image={module.moduleImage}
                  />
                </div>
              ))
            )}
          </div>
        )}
        <Modal
          modalOpen={modalOpen}
          toggleModal={toggleModal}
          title="Add Course Module"
        >
          <CreateCourseOverview toggleModal={toggleModal} />
        </Modal>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default Modules;
