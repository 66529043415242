import React, { useState } from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Select from 'react-select';
import Calendar from '../components/calendar/Calendar';
import { MultiVerticalBar } from '../components/charts/MultiVerticalBar';
import MainContent from '../components/MainContent';
import RightBar from '../components/RightBar';
import Spinner from '../components/spinner/Spinner';
import useFetchAnalytics from '../hooks/useFetchAnalytics';
import useFetchData from '../hooks/useFetchData';

function QuizAnalytics() {
  const [cohortCourseId, setCohortCourseId] = useState('');
  const [cohortModules, setCohortModules] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [moduleId, setModuleId] = useState('');
  const [weekId, setWeekId] = useState('');
  const [quizId, setQuizId] = useState('');
  const [quizName, setQuizName] = useState('');
  const [cohortId, setCohortId] = useState('');

  // get courses

  const { data: courses } = useFetchData(
    ['courses', cohortCourseId],
    '/courses',
    { params: { courses_ids: JSON.stringify([cohortCourseId]) } },
    "Couldn't fetch courses. Please try again!",
    !!cohortId
  );

  const courseOptions = courses
    ? courses?.data?.courses?.map((course) => ({
        value: course.id,
        label: course.courseName,
      }))
    : [];

  // get modules
  const { data: courseModules } = useFetchData(
    ['course-modules', courseId],
    `/course-modules`,
    {
      params: { courseId },
    },
    "Couldn't fetch modules. Please try again!",
    courseId
  );

  const moduleOptions = courseModules
    ? courseModules?.data?.course_modules
        .filter((courseModule) => cohortModules.includes(courseModule.id))
        .map((courseModule) => ({
          value: courseModule.id,
          label: courseModule.moduleName,
        }))
    : [];

  // get module weeks

  const { data: moduleWeeks } = useFetchData(
    ['module-weeks', moduleId],
    `/module-weeks`,
    {
      params: { moduleId },
    },
    "Couldn't fetch weeks. Please try again!",
    moduleId
  );
  const weekOptions = moduleWeeks
    ? moduleWeeks?.data?.weeks?.map((week) => ({
        value: week.id,
        label: week.weekName,
      }))
    : [];

  // get quizes

  const { data: quizes } = useFetchData(
    ['week-quizes', weekId],
    '/quizes',
    {
      params: { weekId },
    },
    "Couldn't get quizes. Please try again!",
    weekId
  );
  const quizOptions = quizes
    ? quizes?.data?.quizes?.map((quiz) => ({
        value: quiz.id,
        label: quiz.quizName,
      }))
    : [];

  const { data: cohortData } = useFetchData(
    ['cohorts'],
    '/cohorts',
    {},
    "Couldn't get cohorts. Please try again!",
    true
  );

  const cohortOptions = cohortData
    ? cohortData?.data?.cohorts?.map((cohort) => ({
        value: cohort.id,
        label: `${cohort.name} (${cohort.no_of_students || 0} Students)`,
        enrolled_course: cohort?.course_id ? cohort.course_id : null,
        enrolled_modules: cohort?.modules ? cohort?.modules : [],
      }))
    : [];

  const { data, isLoading: quizAnalyticsLoading } = useFetchAnalytics(
    ['quiz-analytics', quizId],
    `/quizes/${quizId}`,
    { params: { cohort_id: cohortId } },
    "Couldn't get quiz results. Please try again",
    quizId
  );

  const bgColours = ['#8b173b', '#00999e', '#ffe9f0'];

  return (
    <>
      <MainContent>
        <div className=" space-y-5">
          <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon />}
            >
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/"
                className="flex items-center"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Home</p>
              </NavLink>
              <NavLink
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                to="/analytics"
                className="flex items-center"
              >
                <AnalyticsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                <p>Analytics</p>
              </NavLink>
            </Breadcrumbs>
          </div>
          <div className="bg-white px-2 py-5 rounded-lg">
            <p>
              <span className="font-medium">Quiz Analytics: </span>
              <span className="underline underline-offset-1">
                Detailed insights into quiz submissions by learners, segmented
                by cohort
              </span>
            </p>
          </div>
          <div className="grid grid-cols-4 bg-white p-3 gap-4">
            <Select
              //   {...field}
              isClearable
              placeholder="Select Cohort"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={cohortOptions}
              onChange={(event) => {
                setCohortCourseId(event?.enrolled_course);
                setCohortModules(event?.enrolled_modules);
                setCohortId(event?.value || null);
              }}
            />
            <Select
              //   {...field}
              isClearable
              placeholder="Select Course"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={courseOptions}
              onChange={(event) => {
                setCourseId(event?.value || null);
              }}
            />
            <Select
              //   {...field}
              isClearable
              placeholder="Select Module"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={moduleOptions}
              onChange={(event) => {
                setModuleId(event?.value || null);
              }}
            />
            <Select
              //   {...field}
              isClearable
              placeholder="Select Week"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={weekOptions}
              onChange={(event) => {
                setWeekId(event?.value || null);
              }}
            />
            <Select
              //   {...field}
              isClearable
              placeholder="Select Quiz"
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={quizOptions}
              onChange={(event) => {
                setQuizId(event?.value || null);
                setQuizName(event?.label || null);
              }}
            />
          </div>
          {quizId && quizAnalyticsLoading ? (
            <div className="mt-5 flex justify-center bg-white">
              <Spinner />
            </div>
          ) : (
            <div className="mt-5 grid grid-cols-2 gap-2">
              <div className="bg-white h-[500px] col-span-2">
                <MultiVerticalBar
                  labels={[
                    '0-10',
                    '10-20',
                    '20-30',
                    '30-40',
                    '40-50',
                    '50-60',
                    '60-70',
                    '70-80',
                    '80-90',
                    '90-100',
                  ]}
                  colour="#00999e"
                  title={`Quiz Performance of Students by Gender ${
                    quizName ? `in the ${quizName} Quiz` : ''
                  }`}
                  label="Students"
                  datasets={
                    data
                      ? data?.data?.map((dataset, index) => ({
                          label: dataset.gender,
                          data: dataset.datasets,
                          backgroundColor: bgColours[index],
                        }))
                      : []
                  }
                />
              </div>
            </div>
          )}
        </div>
      </MainContent>
      <RightBar>
        <Calendar />
      </RightBar>
    </>
  );
}

export default QuizAnalytics;
