/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import TableWrapper from '../utils/TableWrapper';
import Spinner from './spinner/Spinner';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import Modal from './Modal';
import P2PReviewsDetails from './P2PReviewDetails';

function GroupSubmissions() {
  const { groupId, groupName } = useParams();

  const [viewReviewsModalOpen, setViewReviewsModalOpen] = useState(false);
  const [submissionData, setSubmissionData] = useState(null);

  const toggleViewReviewsModal = useCallback(() => {
    setViewReviewsModalOpen((prev) => !prev);
  }, []);

  const columns = [
    {
      title: 'Topic',
      field: 'assignment.title',
      render: (row) => <p>{row.assignment.title}</p>,
    },
    {
      title: 'Average Rating',
      field: 'assignment.title',
      render: (row) =>
        row.avg_rating ? (
          <Rating
            size="small"
            className="text-xs"
            name="read-only"
            value={row.avg_rating}
            readOnly
            precision={0.5}
          />
        ) : (
          <p>No ratings yet</p>
        ),
    },
    {
      title: 'Submission File',
      render: (row) => (
        <a href={row?.submission_url} download="file">
          <button
            type="button"
            className=" text-blue-500 px-3 py-1 rounded-md text-xs"
          >
            {row.submission_url}
          </button>
        </a>
      ),
    },
    {
      title: 'Reviews',
      render: (row) => (
        <button
          className={`bg-persian-500 px-4 py-1 rounded-md text-white text-sm cursor-pointer hover:bg-persian-700 space-x-2 flex items-center `}
          type="button"
          onClick={() => {
            setSubmissionData(row);
            toggleViewReviewsModal();
          }}
        >
          <p>View Reviews</p>
        </button>
      ),
    },
  ];

  const { data: group } = useFetchDatav2(
    ['groups', groupId],
    `/groups/${groupId}`,
    {},
    "Couldn't get group details. Please try again!",
    true
  );

  const { data, isLoading } = useFetchDatav2(
    ['group-submissions', groupId],
    '/group-submissions',
    {
      params: { group_id: groupId },
    },
    "Couldn't get group submissions. Please try again!",
    !!groupId
  );

  return (
    <>
      <div className="mt-5">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <TableWrapper
            title={group ? `${groupName} Submissions` : 'Submissions'}
            columns={columns}
            data={data?.data?.group_assignment_submissions || []}
          />
        )}
      </div>
      <Modal
        modalOpen={viewReviewsModalOpen}
        toggleModal={toggleViewReviewsModal}
        title={`${submissionData?.group?.name} ${submissionData?.assignment?.title} Submission Reviews`}
      >
        <P2PReviewsDetails
          submissionData={submissionData}
          toggleModal={toggleViewReviewsModal}
        />
      </Modal>
    </>
  );
}

export default GroupSubmissions;
