import React, { useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import GroupsIcon from '@mui/icons-material/Groups';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import Modal from '../components/Modal';
import EditAssignment from '../components/EditAssignment';
import useFetchDatav2 from '../hooks/useFetchDatav2';
import ViewerEditor from '../components/ViewerEditor';

function AssignmentDetail({ weekly }) {
  const { assignmentId } = useParams();
  const permissions = usePermissions();
  const role = useGetUserRole();
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { data, isLoading } = useFetchDatav2(
    ['assignment', assignmentId],
    `/assignments/${assignmentId}`,
    {},
    "Couldn't get assignment. Please try again!",
    true
  );

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
        {weekly ? (
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/courses"
              className="flex items-center"
            >
              <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Courses</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-1)}
              to={() => navigate(-1)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Modules</p>
            </NavLink>{' '}
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              onClick={() => navigate(-1)}
              to={() => navigate(-1)}
              className="flex items-center"
            >
              <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Module Details</p>
            </NavLink>
          </Breadcrumbs>
        ) : (
          <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/"
              className="flex items-center"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p>Home</p>
            </NavLink>
            <NavLink
              underline="hover"
              sx={{ display: 'flex', alignItems: 'center' }}
              color="inherit"
              to="/groups"
              className="flex items-center"
            >
              <GroupsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              <p className="">Groups</p>
            </NavLink>
          </Breadcrumbs>
        )}
        <div className="flex space-x-4">
          {permissions?.includes(
            'UpdateAssignment' || role === 'SuperAdmin'
          ) && (
            <div className="space-x-2">
              <button
                type="button"
                className="bg-claret-500 text-white text-sm py-1 px-5 rounded-md cursor-pointer"
                onClick={toggleModal}
              >
                Edit Assignment
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 bg-white p-4 ">
        {isLoading ? (
          <div className="flex justify-center items-center align-middle">
            <Spinner />
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-medium tracking-wide mb-2 capitalize">
              {data?.data?.assignment?.title}
            </h2>
            <hr className=" border-gray-900 border-b-[1px] border-spacing-y-8" />
            <ViewerEditor
              initialValue={data?.data?.assignment?.instructions}
              max_height={500}
            />
            <ViewerEditor
              initialValue={data?.data?.assignment?.body}
              max_height={500}
            />
          </>
        )}
      </div>
      <Modal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        title="Edit Assignment"
      >
        <EditAssignment
          assignmentTitle={data ? data?.data?.assignment?.title : ''}
          instructions={data ? data?.data?.assignment?.instructions : ''}
          toggleModal={toggleModal}
          assignmentContent={data ? data?.data?.assignment?.body : ''}
        />
      </Modal>
    </MainContent>
  );
}

export default AssignmentDetail;
