import React, { useState, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Editor } from '@tinymce/tinymce-react';
import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import SaveIcon from '@mui/icons-material/Save';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import usePermissions from '../hooks/usePermissions';
import useGetUserRole from '../hooks/useGetUserRole';
import MainContent from '../components/MainContent';
import Spinner from '../components/spinner/Spinner';
import { axiosInstancev2 } from '../utils/Axios/axiosInterceptorv2';
import tinyMCEConfigs from '../utils/constants/tinymce';

const schema = yup
  .object({
    lessonTopic: yup.string().required('Lesson topic is required'),
    lessonFile: yup
      .mixed()
      .required('Please select a file')
      .test('fileSize', 'File size should be less than 5MB', (value) => {
        return value && value[0]?.size <= 5000000;
      }),
  })
  .required();

function CreateLesson() {
  const navigate = useNavigate();
  const { weekId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contentIsDirty, setContentIsDirty] = useState(false);
  const [lessonContent, setLessonContent] = useState('');
  const queryClient = useQueryClient();

  const permissions = usePermissions();
  const role = useGetUserRole();

  const canCreateLesson =
    permissions?.includes('CreateLesson') || role === 'SuperAdmin';

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // check if is empty
  const isEmpty = lessonContent === '<p><br></p>' || lessonContent === '';

  const editorRef = useRef(null);

  const saveContent = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setContentIsDirty(false);
      editorRef.current.setDirty(false);
      setLessonContent(content);
    }
  };

  const onCreateLessonSuccess = (response_data) => {
    toast.success(
      response_data?.data?.success_message || 'Lesson created successfully'
    );
    queryClient.invalidateQueries(['lessons', weekId]);
    setLessonContent('');
    setIsSubmitting(false);
    reset();
  };

  const onCreateLessonFailure = (error) => {
    toast.error(`Failed to create lesson. ${error?.response?.data?.error}`);
    setIsSubmitting(false);
  };

  const { mutate } = useMutation(
    (data) => {
      return axiosInstancev2.post('/week-lessons', data);
    },
    { onSuccess: onCreateLessonSuccess, onError: onCreateLessonFailure }
  );

  const createLesson = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();

    formData.append('lessonTopic', data?.lessonTopic);
    formData.append('weekId', weekId);
    formData.append('lessonContent', lessonContent);
    formData.append('lessonFile', data?.lessonFile[0]);

    mutate(formData);
  };

  return (
    <MainContent full>
      <div className="bg-white px-2 py-5 rounded-lg flex justify-between">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/"
            className="flex items-center"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Home</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            to="/courses"
            className="flex items-center"
          >
            <BookIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Courses</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-2)}
            to={() => navigate(-2)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Modules</p>
          </NavLink>
          <NavLink
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            onClick={() => navigate(-1)}
            to={() => navigate(-1)}
            className="flex items-center"
          >
            <SchoolIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            <p>Module Overview</p>
          </NavLink>
        </Breadcrumbs>
      </div>
      <div className="mt-4 p-3 bg-white rounded-lg">
        <form onSubmit={handleSubmit(createLesson)} className="space-y-4">
          <div className="flex flex-col space-y-1">
            <label htmlFor="lessonTopic" className="text-base">
              Lesson Topic
            </label>
            <input
              {...register('lessonTopic')}
              type="text"
              id="lessonTopic"
              placeholder="Lesson Topic"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.lessonTopic ? 'border-red-600' : ''
              }`}
            />
            {errors.lessonTopic && (
              <span className="text-red-600 text-xs mt-2">
                {errors.lessonTopic.message}
              </span>
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <label htmlFor="lessonFile" className="text-base">
              Select Lesson File (max 5MB)
            </label>
            <input
              {...register('lessonFile')}
              type="file"
              id="lessonFile"
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.lessonFile ? 'border-red-600' : ''
              }`}
            />
            {errors.lessonFile && (
              <span className="text-red-600 text-xs mt-2">
                {errors.lessonFile.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label htmlFor="lessonContent" className="text-base">
              Lesson Content
            </label>
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              licenseKey="gpl"
              initialValue={lessonContent}
              onInit={(evt, editor) => {
                editorRef.current = editor;
              }}
              onDirty={() => setContentIsDirty(true)}
              init={{
                ...tinyMCEConfigs,
                editable_root: canCreateLesson,
                save_onsavecallback: saveContent,
              }}
            />
            {contentIsDirty && <p>You have unsaved content!</p>}
          </div>
          {isSubmitting ? (
            <div className="grid items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="flex justify-between mt-4">
              <button
                type="submit"
                className={`px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center ${
                  contentIsDirty || isEmpty || Object.keys(errors).length > 0
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#57C13E]'
                }`}
                disabled={
                  contentIsDirty || isEmpty || Object.keys(errors).length > 0
                }
              >
                <SaveIcon fontSize="inherit" />
                <p>Create Lesson</p>
              </button>
            </div>
          )}
        </form>
      </div>
    </MainContent>
  );
}

export default CreateLesson;
